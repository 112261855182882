.table {
  padding: 0 40px 12px 40px;
}
.table td:nth-child(10) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.table tr th:nth-child(6),
.table tr td:nth-child(6) {
  min-width: 68px;
}
.table td:nth-child(9) path,
.table td:nth-child(8) path {
  fill: var(--grey);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 16px 20px;
}
.header {
  display: flex;
  gap: 20px;
  line-height: 40px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 28px;
}
.header button {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}

.device-info {
  display: flex;
  padding: 0 20px;
}
.device-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 12px;
}
.device-item {
  flex: 1;
  display: flex;
  margin-bottom: 12px;
}
.device-title-0,
.device-title-1,
.device-title-2 {
  margin-right: 20px;
  color: var(--dark-grey);
  white-space: nowrap;
}
.device-title-0 {
  width: 60px;
  min-width: 60px;
}
.device-title-1 {
  width: 150px;
  min-width: 150px;
}
.device-title-2 {
  width: 68px;
  min-width: 68px;
}
.device-data {
  flex: 1;
  min-width: 120px;
  white-space: nowrap;
}
.tabs {
  padding: 0 20px;
  margin: 24px 0 16px 0;
}
.info-wrapper {
  flex: 1;
  display: flex;
  gap: 24px;
}
.info {
  padding: 16px 20px;
  flex: 1;
}
.info-row {
  display: flex;
  gap: 40px;
  line-height: 40px;
  border-bottom: 1px solid var(--grey);
}
.info-row:last-child {
  border-bottom: 1px solid transparent;
}
.info-title {
  width: 220px;
  min-width: 220px;
  color: var(--dark-grey);
}
.has-license path {
  fill: var(--green) !important;
}
