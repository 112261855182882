.table {
  padding: 0 40px 12px 40px;
}
.table td:nth-last-child(1),
.table td:nth-last-child(2),
.table td:nth-last-child(3) {
  width: 1%;
  padding: 0 0 0 12px !important;
}
.create-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 320px;
  padding: 35px 40px 40px;
  position: relative;
}
.create-wrapper header {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
  line-height: 30px;
}
.create-close {
  position: absolute !important;
  right: 16px;
  top: 16px;
  width: 24px;
}
.create-field {
  height: 40px;
  width: 320px;
}
